export const S3_AV_STATUS_HEADER_KEY = 'x-amz-meta-av-status';

export enum AVScanStatus {
  SCANNING = 'SCANNING',
  CLEAN = 'CLEAN',
  INFECTED = 'INFECTED',
  NOT_STARTED = 'NOT_STARTED',
}

export const getFileExtension = (fileName?: string | null) => {
  const m = fileName ? fileName.match(/\.(?!.*\.)[a-zA-Z]*(?!\\)/) : '';
  if (m && m.length) {
    return m[0].replace(/\./g, '').toLowerCase();
  }
  return '';
};

export const stripImageNameFromS3URL = (url: string) => {
  // extract image name from url
  if (url.includes('receipts')) {
    return url.split('receipts')[1].slice(1).split('?')[0];
  }

  if (url.includes('supporting_documents')) {
    return url.split('supporting_documents')[1].slice(1).split('?')[0];
  }
  return url;
};
