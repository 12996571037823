import React, {Dispatch, SetStateAction, useState} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAdminPreApprovalRequestQuery} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useSearchParams} from 'react-router-dom';

// Components
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ReviewPreApprovalRequestForm} from './review-pre-approval-request-form';
import {ReviewReimbursementForm} from './review-reimbursement-form';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ComptPill, ComptPillSize} from '@compt/common/forms/compt-pill/compt-pill';
import {getRequestPillType} from '@compt/utils/status-pills-helpers';

interface ReviewRequestSidePanelProps {
  requestId: string | null;
  editMode: boolean;
  setEditMode: Dispatch<SetStateAction<boolean>>;
  requestIdList: number[] | undefined;
}

enum RequestTab {
  'PRE_APPROVAL',
  'REIMBURSEMENT',
}

export const ReviewRequestSidePanel = (props: ReviewRequestSidePanelProps) => {
  const [activeTab, setActiveTab] = useState(RequestTab.PRE_APPROVAL);
  const [, setSearchParams] = useSearchParams();

  const session = useGetSessionQuery();

  const company = useGetCompanyQuery(session.data?.user_id ?? skipToken);
  const requestQuery = useGetAdminPreApprovalRequestQuery(
    {
      companyId: company.data?.id,
      requestId: props.requestId,
    },
    {skip: !company.data?.id || !props.requestId},
  );
  const request = requestQuery.data;
  const requestStatus = request?.status;

  function getPreviousAndNextRequestId(
    requestId: string | null,
    requestIdList: number[] | undefined,
  ) {
    if (!requestId || !requestIdList) return;

    const currentRequestId = parseInt(requestId);
    const currentIndex = requestIdList.indexOf(currentRequestId);

    const prevRequestId = requestIdList[currentIndex - 1] || null;
    const nextRequestId = requestIdList[currentIndex + 1] || null;

    return {prevRequestId, nextRequestId};
  }

  function onPreviousClick() {
    const previousRequestId = getPreviousAndNextRequestId(props.requestId, props.requestIdList)
      ?.prevRequestId;
    if (!previousRequestId) return;

    setSearchParams({request_id: `${previousRequestId}`});
  }

  function onNextClick() {
    const nextRequestId = getPreviousAndNextRequestId(props.requestId, props.requestIdList)
      ?.nextRequestId;
    if (!nextRequestId) return;

    setSearchParams({request_id: `${nextRequestId}`});
  }

  function clearSelectedRequest() {
    props.setEditMode(false);
    setSearchParams({});
  }

  return (
    <ComptSidePanel
      open={!!props.requestId}
      className="max-w-[920px]"
      data-testid="lnd-review-request-form"
    >
      <ComptSidePanel.Header
        title="Review submission"
        setOpen={clearSelectedRequest}
        headerIcon={{id: 'file-icon-blue'}}
        statusPill={
          <span className="flex">
            <h2 className="text-color-body1">&nbsp;Status</h2>
            <ComptPill
              className="ml-2"
              pillSize={ComptPillSize.MEDIUM}
              pillType={getRequestPillType(requestStatus)}
            >
              {requestStatus === 'PENDING_APPROVAL' ? 'PENDING' : requestStatus}
            </ComptPill>
          </span>
        }
      />
      <ComptLoadingIndicator isLoading={requestQuery.isLoading || requestQuery.isFetching}>
        <div className="flex pt-300 px-400 gap-x-6">
          <button
            className={`py-2 text-lg font-medium ${
              activeTab === RequestTab.PRE_APPROVAL
                ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                : 'text-gray-500'
            }`}
            onClick={() => setActiveTab(RequestTab.PRE_APPROVAL)}
          >
            <p className="heading4">Request form</p>
          </button>
          <button
            className={`py-2 text-lg font-medium ${
              activeTab === RequestTab.REIMBURSEMENT
                ? 'border-b-2 border-[var(--Icon-icon-link,#3B7E95)] text-primary'
                : 'text-gray-500'
            }`}
            onClick={() => setActiveTab(RequestTab.REIMBURSEMENT)}
          >
            <p className="heading4">Reimbursement form</p>
          </button>
        </div>
        {activeTab === RequestTab.PRE_APPROVAL && (
          <ReviewPreApprovalRequestForm
            request={request}
            editMode={props.editMode}
            setEditMode={props.setEditMode}
            onPreviousClick={onPreviousClick}
            onNextClick={onNextClick}
            previousDisabled={
              !getPreviousAndNextRequestId(props.requestId, props.requestIdList)?.prevRequestId
            }
            nextDisabled={
              !getPreviousAndNextRequestId(props.requestId, props.requestIdList)?.nextRequestId
            }
          />
        )}
        {activeTab === RequestTab.REIMBURSEMENT && (
          <ReviewReimbursementForm editMode={props.editMode} setEditMode={props.setEditMode} />
        )}
      </ComptLoadingIndicator>
    </ComptSidePanel>
  );
};
