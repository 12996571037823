import React, {useEffect} from 'react';

// Hooks and methods
import {Controller, ControllerRenderProps, useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {twMerge} from 'tailwind-merge';

// RTK queries
import {useUpdateLearningDevelopmentProgramMutation} from '@compt/app/services/api/learning-development-slice';

// Components
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {
  ComptProgressStepper,
  StepStatus,
} from '@compt/common/compt-progress-stepper/compt-progress-stepper';
import {Listbox} from '@headlessui/react';
import {ProgramFormFooter} from '@compt/pages/admin-pages/admin-learning-development/components/form-footer';

// Types
import {LnDProgramForms} from '@compt/pages/admin-pages/admin-learning-development/learning-development.types';
import {
  FundsExpirationStrategyType,
  LearningDevelopmentProgram,
} from '@compt/types/learning-development/learning-development-program';

const fundsExpirationStrategyFormats: Record<FundsExpirationStrategyType, string> = {
  AFTER_APPROVAL: 'days after the request is approved ',
  AFTER_COMPLETION_DATE: 'days after the employee-entered completion date',
};

type FundsExpirationStrategyOption = {label: string; id: FundsExpirationStrategyType};

export const fundsExpirationStrategyOptions: FundsExpirationStrategyOption[] = [
  {
    label: fundsExpirationStrategyFormats[FundsExpirationStrategyType.AFTER_APPROVAL],
    id: FundsExpirationStrategyType.AFTER_APPROVAL,
  },
  {
    label: fundsExpirationStrategyFormats[FundsExpirationStrategyType.AFTER_COMPLETION_DATE],
    id: FundsExpirationStrategyType.AFTER_COMPLETION_DATE,
  },
];

function getFundsExpirationStrategyOption(fundsExpirationStrategy: FundsExpirationStrategyType) {
  return fundsExpirationStrategyOptions.find((type) => type.id === fundsExpirationStrategy);
}

interface FundsExpirationFieldValues {
  funds_expiration_days: number;
  funds_expiration_strategy: FundsExpirationStrategyOption;
}

interface FundsExpirationFormProps {
  existingData: LearningDevelopmentProgram;
}

export const FundsExpirationForm = (props: FundsExpirationFormProps) => {
  const navigate = useNavigate();

  const formMethods = useForm<FundsExpirationFieldValues>();
  const {dirtyFields} = formMethods.formState;
  const [updateProgram, {isLoading: isUpdating}] = useUpdateLearningDevelopmentProgramMutation();
  const {existingData} = props;
  const {progressSteps, setStepStatusMap, stepStatusBase} = useFormStepperContext();

  // Syncs form with initially selected expense type
  useEffect(() => {
    const initialStrategyOption = getFundsExpirationStrategyOption(
      existingData?.funds_expiration_strategy || FundsExpirationStrategyType.AFTER_APPROVAL,
    );

    if (initialStrategyOption) {
      formMethods.setValue('funds_expiration_strategy', initialStrategyOption);
    }
    formMethods.setValue('funds_expiration_days', existingData.funds_expiration_days);
  }, [existingData, formMethods]);

  function onFundsExpirationUpdate(
    form: FundsExpirationFieldValues,
    buttonCallback: (updatedData: LearningDevelopmentProgram) => void,
  ) {
    const updatedSubmission = {
      id: existingData.id,
      name: existingData.name,
      funds_expiration_days: form.funds_expiration_days,
      funds_expiration_strategy: form.funds_expiration_strategy.id,
      company: {
        id: existingData.company.id,
        name: existingData.company.name,
      },
    };

    updateProgram(updatedSubmission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
        return;
      }

      buttonCallback(results.data);
    });
  }

  function onPreviousClick() {
    setStepStatusMap(() => ({
      ...stepStatusBase,
      [LnDProgramForms.ELIGIBILITY_BREAKDOWN]: StepStatus.CURRENT,
    }));
  }

  function onNextClick() {
    if (Object.keys(dirtyFields).length === 0) {
      setStepStatusMap((prevState) => ({
        ...prevState,
        [LnDProgramForms.FUNDS_EXPIRATION]: StepStatus.COMPLETE,
        [LnDProgramForms.PRE_APPROVAL_REQUEST]: StepStatus.CURRENT,
      }));
      return;
    }

    formMethods.handleSubmit((form) =>
      onFundsExpirationUpdate(form, () => {
        setStepStatusMap((prevState) => ({
          ...prevState,
          [LnDProgramForms.FUNDS_EXPIRATION]: StepStatus.COMPLETE,
          [LnDProgramForms.PRE_APPROVAL_REQUEST]: StepStatus.CURRENT,
        }));
      }),
    )();
  }

  function onSaveDraftClick() {
    if (Object.keys(dirtyFields).length === 0) {
      navigate('/manage-programs');
      if (existingData.is_draft) {
        triggerCustomToast(
          'success',
          'Program saved as a draft',
          `Select ${existingData.name} from the list below to complete setup`,
        );
      }
      return;
    }

    formMethods.handleSubmit((form) => {
      onFundsExpirationUpdate(form, (updatedData: LearningDevelopmentProgram) => {
        navigate('/manage-programs');

        if (updatedData.is_draft) {
          triggerCustomToast(
            'success',
            'Program saved as a draft',
            `Select ${updatedData.name} from the list below to complete setup`,
          );
        }
      });
    })();
  }

  const handleOnBlur = (
    e: React.FocusEvent<HTMLInputElement>,
    field: ControllerRenderProps<FundsExpirationFieldValues, 'funds_expiration_days'>,
  ) => {
    const {value} = e.target;
    const fixedValue = value ? parseFloat(value).toFixed(0) : 1;

    field.onChange(fixedValue);
    field.onBlur();
  };

  const daysError = formMethods.formState.errors.funds_expiration_days;

  return (
    <div className="flex flex-col h-full pb-900 justify-between">
      <div className="flex px-600">
        <ComptProgressStepper steps={progressSteps} />
        <div className="min-w-[540px] flex flex-col items-center mt-2 py-1 pl-6">
          <label className="flex items-center body2 text-color-body1 mb-1">
            How long do employees have to submit for reimbursement after their request has been
            approved?
            <p className="text-stroke-critical ml-0.5">*</p>
          </label>
          <div className="w-full relative flex items-center">
            <Controller
              name="funds_expiration_days"
              control={formMethods.control}
              rules={{
                required: 'Number of days is required',
                min: {
                  value: 1,
                  message: 'Value must be between 1 and 1,000.',
                },
                max: {
                  value: 999,
                  message: 'Value must be between 1 and 1,000.',
                },
              }}
              render={({field}) => (
                <input
                  ref={field.ref}
                  type="number"
                  value={field.value}
                  placeholder="Enter # of days"
                  step={1}
                  min={1}
                  required
                  onChange={field.onChange}
                  onBlur={(e) => handleOnBlur(e, field)}
                  className={twMerge(`
          block w-full rounded-md border-0 py-100 px-200 shadow-sm ring-1 ring-inset
          body1 text-color-body2
          placeholder:text-gray-400 sm:leading-6 focus:ring-2 disabled:bg-surface-disabled
          disabled:text-disabled-on-light
          ${
            daysError
              ? 'ring-stroke-critical focus:ring-stroke-critical'
              : 'ring-stroke-tertiary focus:ring-stroke-focus'
          }
        `)}
                />
              )}
            />
            <span className="z-40 mr-3 absolute flex top-1/2 right-0 transform -translate-y-1/2">
              <Controller
                control={formMethods.control}
                rules={{required: true}}
                name="funds_expiration_strategy"
                render={({field: {onChange, value, ref}}) => (
                  <Listbox value={value} by="id" onChange={onChange}>
                    <div className="relative" dir="ltr">
                      <Listbox.Button ref={ref} className="flex relative group">
                        <p className="body1 mr-2">{value?.label}</p>
                        <ComptSvgIcon
                          iconName="chevron-down-icon"
                          svgProp={{width: '24px', height: '24px'}}
                        />
                      </Listbox.Button>
                      <Listbox.Options
                        className={`absolute max-h-32 rounded-md left-0 z-10 w-0 min-w-full
                 bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none`}
                      >
                        {fundsExpirationStrategyOptions?.map((strategyOption) => (
                          <Listbox.Option
                            key={strategyOption.id}
                            value={strategyOption}
                            className={({active}) =>
                              twMerge(`
                    relative flex cursor-default select-none py-2 px-3
                    ${active && ' bg-gray-200 cursor-pointer'}
                    `)
                            }
                          >
                            {({selected}) => (
                              <div className="flex justify-between items-center">
                                <p className="pr-2 body2">{strategyOption.label}</p>
                                {selected ? (
                                  <ComptSvgIcon
                                    iconName="check-icon"
                                    svgProp={{width: '16px', height: '16px'}}
                                  />
                                ) : null}
                              </div>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </div>
                  </Listbox>
                )}
              />
            </span>
          </div>
          {daysError && (
            <p className="text-color-error body3 my-2  self-start">{daysError?.message}</p>
          )}
        </div>
      </div>

      <ProgramFormFooter className="flex flex-shrink-0">
        <ComptButton
          size={ComptButtonSize.LARGE}
          buttonType={ComptButtonType.BORDERLESS}
          disabled={isUpdating}
          onClick={() => {
            formMethods.reset();
            navigate('/manage-programs');
          }}
        >
          Cancel
        </ComptButton>
        <div className="flex gap-x-6">
          <ComptButton
            onClick={onPreviousClick}
            size={ComptButtonSize.LARGE}
            buttonType={ComptButtonType.BORDERLESS}
            disabled={isUpdating}
          >
            Previous
          </ComptButton>
          <ComptButton
            onClick={onSaveDraftClick}
            size={ComptButtonSize.LARGE}
            buttonType={ComptButtonType.SECONDARY}
            disabled={!formMethods.formState.isDirty && !isUpdating}
          >
            Save draft
          </ComptButton>
          <ComptButton
            onClick={onNextClick}
            size={ComptButtonSize.LARGE}
            buttonType={ComptButtonType.PRIMARY}
            disabled={isUpdating}
          >
            Next
          </ComptButton>
        </div>
      </ProgramFormFooter>
    </div>
  );
};
