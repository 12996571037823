import React, {useState, useMemo} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetSessionQuery} from '@compt/app/services/api/api-slice';
import {useGetCompanyQuery} from '@compt/app/services/api/company-slice';
import {useGetAllAdminPreApprovalRequestsQuery} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {
  ReviewLnDTableController as controller,
  MAX_REQUESTS_PER_PAGE,
} from '../review-lnd-table.controller';
import {useSearchParams} from 'react-router-dom';

// Components
import {ComptTable} from '@compt/common/compt-table/compt-table.container';
import {ComptLoadingIndicator} from '@compt/common/compt-loading/compt-loading';
import {ReviewRequestSidePanel} from './review-lnd-request-side-panel';

// Types
import {SupportedCountriesType} from '@compt/utils/international-helpers';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {FormattedFilterObject} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {useDebounce} from '@uidotdev/usehooks';
import {MAX_ITEMS_PER_PAGE} from '@compt/constants';

interface ReviewLnDTableProps {
  selectedStatuses: string[];
}

export const ReviewLnDTable: React.FC<ReviewLnDTableProps> = ({selectedStatuses}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const requestId = searchParams.get('request_id');

  const [isEditingMode, setIsEditingMode] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState<boolean>(false);
  const [paginationOffset, setPaginationOffset] = useState(0);

  const initialFilterValues = controller.getInitialFilterValues();
  const [queryParams, setQueryParams] = useState<FormattedFilterObject | undefined>({
    limit: MAX_REQUESTS_PER_PAGE,
    offset: paginationOffset,
  });

  const debouncedFilterValues = useDebounce(queryParams, 300);

  const session = useGetSessionQuery();
  const company = useGetCompanyQuery(session.data?.user_id ?? skipToken);

  // TO-DO: Implement in COMPT-5767
  // useEffect(() => {
  //   setQueryParams((prevParams) => {
  //     const updatedParams: FormattedFilterObject = {
  //       ...prevParams,
  //     };

  //     if (selectedStatuses.length > 0) {
  //       updatedParams.status = selectedStatuses.join(',');
  //       updatedParams.include_reviewed = 'true';
  //       updatedParams.offset = 0;
  //     } else {
  //       delete updatedParams.status;
  //       delete updatedParams.include_reviewed;
  //       delete updatedParams.offset;
  //     }

  //     return updatedParams;
  //   });
  // setPaginationOffset(0);
  // }, [selectedStatuses]);

  const preApprovalRequests = useGetAllAdminPreApprovalRequestsQuery(
    {companyId: company.data?.id, params: debouncedFilterValues},
    {skip: !company.data?.id},
  );

  const preApprovalRequestIdList = preApprovalRequests.data?.results.map((request) => request.id);
  const totalCount = preApprovalRequests.data?.count ?? 0;

  const columnDefinition = controller.getColumnDefinition(
    session.data?.country as SupportedCountriesType,
    onActionMenuClicked,
  );

  const filterConfiguration = controller.getFilterConfiguration();

  function onActionMenuClicked(reviewData: PreApprovalRequest, action: 'VIEW' | 'EDIT' | 'DELETE') {
    switch (action) {
      case 'VIEW':
        setSearchParams({request_id: `${reviewData.id}`});
        setIsEditingMode(false);
        break;
      case 'EDIT':
        setSearchParams({request_id: `${reviewData.id}`});
        setIsEditingMode(true);
        break;
      case 'DELETE':
        break;
    }
  }

  const noDataTitleText = useMemo(
    () =>
      filtersApplied
        ? 'No requests were found on this page with the current filters.'
        : 'No requests have been submitted yet',
    [filtersApplied],
  );

  const noDataSubtitle = useMemo(
    () =>
      filtersApplied ? (
        <p />
      ) : (
        <p className="body1 text-center">
          Start a request under Learning & Development to the left
        </p>
      ),
    [filtersApplied],
  );

  return (
    <ComptLoadingIndicator
      isLoading={company.isLoading || preApprovalRequests.isLoading}
      className="pt-72"
    >
      <ReviewRequestSidePanel
        requestId={requestId}
        editMode={isEditingMode}
        setEditMode={setIsEditingMode}
        requestIdList={preApprovalRequestIdList}
      />
      {company.data && (
        <ComptTable
          company={company.data}
          tableId="review-lnd-table"
          className="w-full"
          data={preApprovalRequests.data?.results || []}
          dataLoading={preApprovalRequests.isLoading}
          totalCount={totalCount}
          itemsPerPage={MAX_REQUESTS_PER_PAGE}
          allowPagination={true}
          columnDefinition={columnDefinition}
          allowShowHide={false}
          initialFilterValues={initialFilterValues}
          // filterConfiguration={filterConfiguration}
          noDataTitleText={noDataTitleText}
          noDataSubtitle={noDataSubtitle}
          onRowClicked={(row) => setSearchParams({request_id: `${row.id}`})}
          onChangeQueryValues={(filterValues, pagination, ordering) => {
            controller.updateQueryParamsOnValuesChanged(
              company?.data?.id,
              setQueryParams,
              setFiltersApplied,
              pagination,
              filterValues,
              ordering,
            );
          }}
          stickyLastColumn
        />
      )}
    </ComptLoadingIndicator>
  );
};
