import {ComptStyleType} from '@compt/utils/style-helpers';
import {ExpenseStatus} from '@compt/types/stipend-expense';
import {PayloadLearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

export function getPillType(status: ExpenseStatus | string | undefined) {
  switch (status) {
    case ExpenseStatus.Approved:
    case 'Approved':
      return ComptStyleType.SUCCESS;

    case ExpenseStatus.Processed:
    case 'Processed':
      return ComptStyleType.PRIMARY;

    case ExpenseStatus.Rejected:
    case 'Rejected':
      return ComptStyleType.DESTRUCTIVE;

    case ExpenseStatus.Open:
    case 'Open':
      return ComptStyleType.WARNING;

    case ExpenseStatus.InReview:
    case 'In review':
      return ComptStyleType.SECONDARY;

    default:
      return ComptStyleType.GRAY;
  }
}

export function getRequestPillType(status: PayloadLearningRequestStatus | string | undefined) {
  switch (status) {
    case PayloadLearningRequestStatus.APPROVED:
    case 'APPROVED':
      return ComptStyleType.SUCCESS;

    case PayloadLearningRequestStatus.PENDING_APPROVAL:
    case 'PENDING_APPROVAL':
      return ComptStyleType.SECONDARY;

    case PayloadLearningRequestStatus.REJECTED:
    case 'REJECTED':
      return ComptStyleType.DESTRUCTIVE;

    case PayloadLearningRequestStatus.DRAFT:
    case 'DRAFT':
      return ComptStyleType.GRAY;

    default:
      return ComptStyleType.GRAY;
  }
}
