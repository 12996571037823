import React, {Dispatch, SetStateAction} from 'react';

// RTK queries
import {useUpdateLearningDevelopmentProgramMutation} from '@compt/app/services/api/learning-development-slice';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';
import {DATE_FORMAT_OPTION, formattedDate, getUTCDateFromString} from '@compt/utils/date-helpers';

// Types
import {DateString} from '@compt/types/common/date-string';
import {LearningDevelopmentProgram} from '@compt/types/learning-development/learning-development-program';

// Components
import {ComptButton, ComptButtonType} from '@compt/common/compt-button/compt-button';
import {ComptSidePanel} from '@compt/common/compt-side-panel/compt-side-panel';
import {ComptDatePickerField} from '@compt/common/forms/compt-date-picker-field/compt-date-picker-field';

interface TerminationFieldValues {
  termination_date: DateString;
}

interface TerminationConfirmationModalProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  programData: LearningDevelopmentProgram;
}

export const ConfirmTerminationSidePanel = (props: TerminationConfirmationModalProps) => {
  const {open, setOpen, programData} = props;

  const formMethods = useForm<TerminationFieldValues>();

  const [updateProgram, {isLoading: isUpdating}] = useUpdateLearningDevelopmentProgramMutation();

  function onTerminationSubmit(form: TerminationFieldValues) {
    const submission = {
      id: programData.id,
      name: programData.name,
      company: programData.company,
      termination_date: form.termination_date,
    };

    updateProgram(submission).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem terminating your program',
          'Please try again.',
        );
        return;
      }

      triggerCustomToast(
        'success',
        'Program terminated successfully',
        `${results.data.name} is scheduled for termination on ${formattedDate(
          results.data.termination_date,
          DATE_FORMAT_OPTION['month dd yyyy'],
        )}.`,
      );
    });
  }

  return (
    <ComptSidePanel open={!!open} className="max-w-2xl">
      <ComptSidePanel.Header
        title="Terminate program"
        setOpen={() => setOpen(false)}
        headerIcon={{id: 'star-icon'}}
        formMethods={formMethods}
      />
      <ComptSidePanel.Content className="py-6 px-6">
        <ComptDatePickerField
          name="termination_date"
          label="Select termination date"
          control={formMethods.control}
          register={formMethods.register}
          validation={{required: 'Please select a termination date'}}
          errors={formMethods.formState.errors.termination_date}
          startDate={getUTCDateFromString(new Date()) || null}
          allowFutureDates
        />
      </ComptSidePanel.Content>
      <ComptSidePanel.Footer>
        <div className="flex gap-x-2">
          <ComptButton
            buttonType={ComptButtonType.OUTLINED}
            disabled={isUpdating}
            onClick={() => {
              formMethods.reset();
              setOpen(false);
            }}
          >
            Cancel
          </ComptButton>
          <ComptButton
            buttonType={ComptButtonType.DESTRUCTIVE}
            disabled={isUpdating}
            onClick={formMethods.handleSubmit(onTerminationSubmit)}
          >
            Terminate program
          </ComptButton>
        </div>
      </ComptSidePanel.Footer>
    </ComptSidePanel>
  );
};
