import {DateString} from '@compt/types/common/date-string';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

export const LearningStatusFormats: Record<
  LearningRequestStatus,
  {label: string; statusStyle: string}
> = {
  [LearningRequestStatus.DRAFT]: {
    label: 'New request',
    statusStyle: 'success',
  },
  [LearningRequestStatus.PENDING_APPROVAL]: {
    label: 'Ready for review',
    statusStyle: 'secondary',
  },
  [LearningRequestStatus.APPROVED_BY_PRIMARY_APPROVER]: {
    label: 'Approved by primary approver',
    statusStyle: 'primary',
  },
  [LearningRequestStatus.APPROVED_BY_SECONDARY_APPROVER]: {
    label: 'Approved by secondary approver',
    statusStyle: 'primary',
  },
  [LearningRequestStatus.APPROVED_BY_REIMBURSEMENT_APPROVER]: {
    label: 'Approved by reimbursement approver',
    statusStyle: 'primary',
  },
  [LearningRequestStatus.APPROVED]: {
    label: 'Approved',
    statusStyle: 'primary',
  },
  [LearningRequestStatus.REJECTED]: {
    label: 'Rejected',
    statusStyle: 'destructive',
  },
};

export interface ReviewLndPayload {
  id: number;
  user: {
    full_name: string;
    email: string;
  };
  request_information: {
    request_number: string;
    amount: number;
    program: string;
    last_updated: DateString;
    status: string;
  };
}
