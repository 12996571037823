import React, {useEffect, useState, useRef, useCallback} from 'react';

// RTK queries
import {skipToken} from '@reduxjs/toolkit/dist/query';
import {useGetAllEmployeePreApprovalRequestsQuery} from '@compt/app/services/api/employee-learning-development-slice';

// Hooks and methods
import {produce} from 'immer';
import {
  DEFAULT_FILTER_STATE,
  FilterValuesType,
  TeamRecognitionActivityFeedController,
} from '@compt/pages/team-recognition-page/components/team-recognition-activity-feed.controller';
import {useCacheableObjectState} from '@compt/utils/local-storage-helpers';

// Types
import {UserSession} from '@compt/types/account';
import {Company} from '@compt/types/company';
import {EmployeePreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';

// Components
import {ComptLoadingAnimation} from '@compt/common/compt-loading/compt-loading-animation';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {ComptReimbursementCard} from '@compt/common/compt-reimbursement-card/compt-reimbursement-card';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';

export interface TeamRecognitionActivityFeedProps {
  userSession: UserSession;
  company: Company;
}

const ActivityList = ({
  preApprovalRequests,
  company,
}: {
  preApprovalRequests: EmployeePreApprovalRequest[] | undefined;
  company: Company;
}) => {
  // TO-DO: Account for COMPLETED status after COMPT-5771.
  const completedRequests = preApprovalRequests?.filter(
    (request) => request.status === LearningRequestStatus.REJECTED,
  );
  const activeRequests = preApprovalRequests?.filter(
    (request) => request.status !== LearningRequestStatus.REJECTED,
  );
  return (
    <div className="">
      {activeRequests && activeRequests.length > 0 && (
        <>
          <p className="heading1 mb-4">Active requests ({activeRequests?.length})</p>
          {activeRequests.map((request) => (
            <div key={`active-request-card-${request.id}`} className="mb-400">
              <ComptReimbursementCard request={request} company={company} />
            </div>
          ))}
        </>
      )}
      {completedRequests && completedRequests.length > 0 && (
        <>
          <p className="heading1 mb-4">Completed ({completedRequests?.length})</p>
          {completedRequests.map((request, i) => (
            <div key={`completed-request-card-${request.id}`} className="mb-400">
              <ComptReimbursementCard request={request} company={company} />
            </div>
          ))}
        </>
      )}
      {!activeRequests?.length && !completedRequests?.length && (
        <div className="flex flex-col items-center">
          <ComptSvgIcon iconName="empty-img-icon" className="mb-6" />
          <h4 className="text-center">Start a request on the right!</h4>
        </div>
      )}
    </div>
  );
};

export const LearningActivityFeed = ({company, userSession}: TeamRecognitionActivityFeedProps) => {
  const controller = new TeamRecognitionActivityFeedController(userSession, company);
  const [filterValues, setFilterValues] = useCacheableObjectState<FilterValuesType>(
    controller.getFilterKey(),
    DEFAULT_FILTER_STATE,
    false,
    ['pagination'],
  ) as [FilterValuesType, React.Dispatch<React.SetStateAction<FilterValuesType>>];
  const [, setCurrentPage] = useState(1);
  const [, setPreApprovalRequest] = useState<EmployeePreApprovalRequest[]>([]);
  const [nextQuery, setNextQuery] = useState<boolean | null>(true);
  const loadMoreRef = useRef<HTMLDivElement>(null);

  const allPreApprovalRequests = useGetAllEmployeePreApprovalRequestsQuery(company.id ?? skipToken);

  useEffect(() => {
    if (allPreApprovalRequests.data) {
      if (filterValues?.pagination?.page === 1) {
        setPreApprovalRequest(allPreApprovalRequests.data.results || []);
      } else {
        setPreApprovalRequest((prevData) => [
          ...prevData,
          ...(allPreApprovalRequests.data?.results || []),
        ]);
      }
      setNextQuery(!!allPreApprovalRequests.data.next);
    }
  }, [allPreApprovalRequests.data]);

  const onFilterChange = useCallback(
    (filterKey: keyof FilterValuesType, newValue: string | {page: number}) => {
      setFilterValues(
        produce((draft) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore: this actually works since we know filterKey is a keyof FilterValuesType
          draft[filterKey] = newValue;
          return draft;
        }),
      );
    },
    [setFilterValues],
  );

  const handleLoadMore = useCallback(() => {
    setCurrentPage((prevPage) => {
      const nextPage = prevPage + 1;
      onFilterChange('pagination', {page: nextPage});
      return nextPage;
    });
  }, [onFilterChange]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && !allPreApprovalRequests.isLoading) {
        handleLoadMore();
      }
    });
    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
  }, [handleLoadMore, allPreApprovalRequests.isLoading]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col max-h-max mb-24">
          <ActivityList
            preApprovalRequests={allPreApprovalRequests.data?.results ?? []}
            company={company}
          />
          <div ref={loadMoreRef}>
            {(allPreApprovalRequests.isLoading || allPreApprovalRequests.isFetching) &&
              nextQuery && (
                <div className="flex flex-col w-full mt-6">
                  <div className="mb-[9px]">
                    <ComptLoadingAnimation />
                  </div>
                  <p className="label3 text-color-body1 -mt-7 mx-auto">Loading more requests...</p>
                </div>
              )}
          </div>
        </div>
      </div>
    </>
  );
};
