import React from 'react';
import {ComptCard} from '@compt/common/compt-card/compt-card';
import {ComptSvgIcon} from '@compt/common/compt-svg-icon/compt-svg-icon';
import {PerkCategory} from '@compt/types/perk-category';

interface VendorCardProps {
  logoSrc: string;
  title: string;
  description: string;
  perkCategories?: PerkCategory[];
}

export const VendorCard = ({logoSrc, title, description, perkCategories}: VendorCardProps) => (
  <ComptCard>
    <div className="flex flex-col items-center p-4 px-6 text-center gap-4 h-[270px]">
      <div
        className="absolute w-[128px] h-[128px] flex justify-center items-center bg-white 
  left-1/2 top-[-24px] transform -translate-x-1/2 rounded-xl shadow-[0px_6px_20px_0px_rgba(0,0,0,0.08)]"
      >
        <img src={logoSrc} alt={title} className="w-full h-full object-cover rounded-xl" />
      </div>

      <div className="flex flex-col items-center gap-2 max-w-[198px] mt-[112px]">
        <h3 className="text-xl font-semibold text-gray-800 mb-1">{title}</h3>
        <p className="text-md text-gray-600">{description}</p>
        <div className="flex -space-x-2">
          {perkCategories &&
            perkCategories?.length > 0 &&
            perkCategories.map(({internal_id, id}) => (
              <ComptSvgIcon
                key={id}
                iconName={internal_id}
                isCategoryIcon
                svgProp={{width: '20px', height: '20px'}}
                className="w-8 h-8 border-2 border-white bg-[white] rounded-full 
            flex items-center justify-center"
              />
            ))}
        </div>
      </div>
    </div>
  </ComptCard>
);
