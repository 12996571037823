import React, {Dispatch, SetStateAction} from 'react';
import {SearchFilter} from '@compt/common/compt-filter-bar/compt-search-filter';
import {SelectFilter} from '@compt/common/compt-filter-bar/compt-select-filter';
import {
  FilterValues,
  FormattedFilterObject,
} from '@compt/common/compt-filter-bar/compt-filter-bar.types';
import {Pagination} from '@compt/common/compt-table/compt-table.types';
import {selectionOptionValuesChangeHandler} from '@compt/common/compt-filter-bar/compt-filter-bar.utils';
import {LearningRequestStatus} from '@compt/types/learning-development/learning-request-status';
import {LearningStatusFormats} from './review-lnd.types';
import {
  formatCurrencyFromCountryCode,
  SupportedCountriesType,
} from '@compt/utils/international-helpers';
import {PreApprovalRequest} from '@compt/types/learning-development/pre-approval-request';
import {DATE_FORMAT_OPTION, formattedDate} from '@compt/utils/date-helpers';
import {ComptPill} from '@compt/common/forms/compt-pill/compt-pill';
import {SimpleAction, SimpleActionMenu} from '@compt/common/compt-table/simple-action-menu';

export const MAX_REQUESTS_PER_PAGE = 15;

export class ReviewLnDTableController {
  static getInitialFilterValues() {
    return {
      textSearch: [''],
      status: [],
    };
  }

  static getFilterConfiguration() {
    const filterConfiguration = {
      textSearch: {
        filterType: SearchFilter,
        label: 'Review L&D Table Search',
        options: [
          {
            id: 1,
            name: 'Review L&D Table Search',
            placeholder: 'Search',
          },
        ],
        nonCollapsible: true,
      },
      status: {
        filterType: SelectFilter,
        label: 'Show/hide columns',
        options: [],
        valuesChangeHandler: selectionOptionValuesChangeHandler,
        getKey: (object: unknown) => object,
      },
    };
    return filterConfiguration;
  }

  static updateQueryParamsOnValuesChanged(
    companyId: number | undefined,
    setQueryParams: Dispatch<SetStateAction<FormattedFilterObject | undefined>>,
    setFiltersApplied: Dispatch<SetStateAction<boolean>>,
    pagination?: Pagination | null,
    filterValues?: FilterValues,
    ordering?: string | null,
  ) {
    if (!companyId) return;

    const formattedFilter = this.formatFilterValuesOptions(filterValues, pagination, ordering);

    setQueryParams(formattedFilter);

    if (filterValues?.status?.length || (filterValues?.textSearch && filterValues?.textSearch[0])) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }
  }

  static formatFilterValuesOptions(
    filterValues?: FilterValues,
    pagination?: Pagination | null,
    ordering?: string | null,
  ): FormattedFilterObject {
    const formattedFilter: FormattedFilterObject = {};

    if (filterValues?.status?.length) {
      formattedFilter['status__in'] = filterValues.status.join(',');
    }
    if (filterValues?.textSearch && filterValues?.textSearch[0]) {
      formattedFilter['search'] = filterValues.textSearch[0];
    }

    formattedFilter.limit = MAX_REQUESTS_PER_PAGE;
    if (pagination) {
      formattedFilter.offset = MAX_REQUESTS_PER_PAGE * Math.max(pagination.page - 1, 0);
      formattedFilter.page = pagination.page;
    } else {
      formattedFilter.page = 1;
      formattedFilter.offset = 0;
    }
    if (ordering) {
      formattedFilter.ordering = ordering;
    }

    return formattedFilter;
  }

  static getColumnDefinition(
    userCountryCode: SupportedCountriesType | undefined = 'US',
    onMenuClicked: (reviewData: PreApprovalRequest, action: 'EDIT' | 'VIEW' | 'DELETE') => void,
  ) {
    const columnDefinition = {
      request_number: {
        id: 'request_number',
        name: 'Request number',
        grow: 0.7,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.id}</p>
        ),
        order: 1,
        omit: false,
      },
      employee_name: {
        id: 'employee_name',
        name: 'Employee name',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.user?.full_name}</p>
        ),
        order: 2,
        omit: false,
      },
      amount: {
        id: 'amount',
        name: 'Amount',
        grow: 0.5,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">
            {formatCurrencyFromCountryCode(reviewData?.request_data?.['Amount'], userCountryCode)}
          </p>
        ),
        order: 3,
        omit: false,
      },
      program: {
        id: 'program',
        name: 'Program',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">{reviewData?.program?.name}</p>
        ),
        order: 4,
      },
      last_updated: {
        id: 'last_updated',
        name: 'Last updated',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => (
          <p data-tag="allowRowEvents">
            {formattedDate(reviewData.modified_on, DATE_FORMAT_OPTION['month dd yyyy'])}
          </p>
        ),
        order: 5,
        omit: false,
      },
      status: {
        id: 'status',
        name: 'Status',
        grow: 1,
        selector: (reviewData: PreApprovalRequest) => {
          const statusFormat = LearningStatusFormats[reviewData.status as LearningRequestStatus];
          return (
            <ComptPill pillType={statusFormat.statusStyle} data-tag="allowRowEvents">
              {statusFormat.label}
            </ComptPill>
          );
        },
        order: 6,
        omit: false,
      },
      action: {
        id: 'action',
        ignoreRowClick: true,
        compact: true,
        selector: (reviewData: PreApprovalRequest) => {
          const _onMenuClicked = (
            reviewData: PreApprovalRequest,
            action: 'VIEW' | 'EDIT' | 'DELETE',
          ) => {
            onMenuClicked(reviewData, action);
          };
          const actions: Array<SimpleAction<PreApprovalRequest>> = [
            {
              label: 'View',
              onClick: (reviewData) => _onMenuClicked(reviewData, 'VIEW'),
            },
          ];

          if (reviewData.status === LearningRequestStatus.PENDING_APPROVAL) {
            actions.push({
              label: 'Edit',
              onClick: (reviewData) => _onMenuClicked(reviewData, 'EDIT'),
            });
          }

          return <SimpleActionMenu relatedActionItem={reviewData} actions={actions} />;
        },
        grow: 0,
        order: 7,
      },
    };

    return columnDefinition;
  }
}
