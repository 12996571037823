import React from 'react';

// Hooks and methods
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {useFormStepperContext} from '@compt/common/compt-form-stepper/compt-form-stepper';

// Components
import {
  ComptButton,
  ComptButtonSize,
  ComptButtonType,
} from '@compt/common/compt-button/compt-button';
import {ProgramFormFooter} from '@compt/pages/admin-pages/admin-learning-development/components/form-footer';
import {
  ComptProgressStepper,
  StepStatus,
} from '@compt/common/compt-progress-stepper/compt-progress-stepper';

// Types
import {LnDProgramForms} from '@compt/pages/admin-pages/admin-learning-development/learning-development.types';
import {
  useCreateLearningDevelopmentProgramReviewingConfigurationMutation,
  useUpdateLearningDevelopmentProgramPrimaryApproversMutation,
  useUpdateLearningDevelopmentProgramReimbursementReviewersMutation,
  useUpdateLearningDevelopmentProgramReviewingConfigurationMutation,
  useUpdateLearningDevelopmentProgramSecondaryApproversMutation,
} from '@compt/app/services/api/learning-development-slice';
import {
  ApproverReviewerFields,
  employeeManagerOption,
  // eslint-disable-next-line max-len
} from '@compt/pages/admin-pages/admin-learning-development/components/approver-reviewer/approver-reviewer-fields';
import {ApproverReviewerFieldValues} from '@compt/pages/admin-pages/admin-learning-development/components/approver-reviewer/approver-reviewer.types';
import {
  LearningDevelopmentProgram,
  ProgramReviewingConfiguration,
  ReviewingUser,
} from '@compt/types/learning-development/learning-development-program';
import {triggerCustomToast} from '@compt/common/compt-toaster/compt-toaster';

interface ApproverReviewerFormProps {
  existingData: LearningDevelopmentProgram;
}

export const ApproverReviewerForm = (props: ApproverReviewerFormProps) => {
  const {existingData} = props;

  const navigate = useNavigate();

  const formMethods = useForm<ApproverReviewerFieldValues>(
    existingData.program_reviewing_configuration
      ? {
          values: {
            primary_approvers: [
              ...existingData.program_reviewing_configuration.primary_approvers,
              ...(existingData.program_reviewing_configuration
                .use_employee_manager_as_primary_approver
                ? [
                    {
                      id: 0,
                      full_name: "Employee's Manager",
                      email: 'employee-manager',
                    },
                  ]
                : []),
            ],
            secondary_approvers: [
              ...existingData.program_reviewing_configuration.secondary_approvers,
              ...(existingData.program_reviewing_configuration
                .use_employee_manager_as_secondary_approver
                ? [
                    {
                      id: 0,
                      full_name: "Employee's Manager",
                      email: 'employee-manager',
                    },
                  ]
                : []),
            ],
            reimbursement_reviewers: [
              ...existingData.program_reviewing_configuration.reimbursement_reviewers,
              ...(existingData.program_reviewing_configuration
                .use_employee_manager_as_reimbursement_reviewer
                ? [
                    {
                      id: 0,
                      full_name: "Employee's Manager",
                      email: 'employee-manager',
                    },
                  ]
                : []),
            ],
          },
        }
      : {},
  );

  const {dirtyFields} = formMethods.formState;

  const {progressSteps, setStepStatusMap, stepStatusBase} = useFormStepperContext();
  const [createReviewConfiguration] =
    useCreateLearningDevelopmentProgramReviewingConfigurationMutation();
  const [updateReviewConfiguration, {isLoading: reviewUpdating}] =
    useUpdateLearningDevelopmentProgramReviewingConfigurationMutation();
  const [updatePrimaryApprovers, {isLoading: primaryApproversUpdating}] =
    useUpdateLearningDevelopmentProgramPrimaryApproversMutation();
  const [updateSecondaryApprovers, {isLoading: secondaryApproversUpdating}] =
    useUpdateLearningDevelopmentProgramSecondaryApproversMutation();
  const [updateReimbursementReviewers, {isLoading: reimbursementReviewersUpdating}] =
    useUpdateLearningDevelopmentProgramReimbursementReviewersMutation();

  const isUpdating =
    reviewUpdating ||
    primaryApproversUpdating ||
    secondaryApproversUpdating ||
    reimbursementReviewersUpdating;
  function onPreviousClick() {
    setStepStatusMap(() => ({
      ...stepStatusBase,
      [LnDProgramForms.REIMBURSEMENT_REQUEST]: StepStatus.CURRENT,
    }));
  }

  function onNextClick() {
    if (Object.keys(dirtyFields).length === 0 && formMethods.formState.isValid) {
      setStepStatusMap((prevState) => ({
        ...prevState,
        [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.COMPLETE,
        [LnDProgramForms.REVIEW]: StepStatus.CURRENT,
      }));
      return;
    }

    formMethods.handleSubmit((form) =>
      onApproverReviewerUpdate(form, () => {
        setStepStatusMap(() => ({
          ...stepStatusBase,
          [LnDProgramForms.APPROVER_REVIEWER]: StepStatus.COMPLETE,
          [LnDProgramForms.REVIEW]: StepStatus.CURRENT,
        }));
      }),
    )();
  }

  function primaryApproverUpdate(primaryApprovers: ReviewingUser[], configurationId: number) {
    const approvers = primaryApprovers.map((user) => ({
      user_id: user.id,
      program_reviewing_configuration: configurationId,
    }));
    updatePrimaryApprovers({
      body: approvers,
      companyId: existingData.company.id,
      programId: existingData.id,
      configurationId: configurationId,
    }).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
      }
      return;
    });
  }

  function secondaryApproverUpdate(secondaryApprovers: ReviewingUser[], configurationId: number) {
    const approvers = secondaryApprovers.map((user) => ({
      user_id: user.id,
      program_reviewing_configuration: configurationId,
    }));
    updateSecondaryApprovers({
      body: approvers,
      companyId: existingData.company.id,
      programId: existingData.id,
      configurationId: configurationId,
    }).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
      }
      return;
    });
  }

  function reimbursementReviewerUpdate(
    reimbursementReviewers: ReviewingUser[],
    configurationId: number,
  ) {
    const approvers = reimbursementReviewers.map((user) => ({
      user_id: user.id,
      program_reviewing_configuration: configurationId,
    }));
    updateReimbursementReviewers({
      body: approvers,
      companyId: existingData.company.id,
      programId: existingData.id,
      configurationId: configurationId,
    }).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
      }
      return;
    });
  }

  function onApproverReviewerUpdate(
    form: ApproverReviewerFieldValues,
    buttonCallback: (updatedData: ProgramReviewingConfiguration) => void,
  ) {
    const primaryApprovers = form.primary_approvers.filter(
      (user) => user.email !== employeeManagerOption.value.email,
    );
    const secondaryApprovers = form.secondary_approvers.filter(
      (user) => user.email !== employeeManagerOption.value.email,
    );
    const reimbursementReviewers = form.reimbursement_reviewers.filter(
      (user) => user.email !== employeeManagerOption.value.email,
    );
    const employeeManagerAsPrimaryApprover =
      primaryApprovers.length !== form.primary_approvers.length;
    const employeeManagerAsSecondaryApprover =
      secondaryApprovers.length !== form.secondary_approvers.length;
    const employeeManagerAsReimbursementReviewer =
      reimbursementReviewers.length !== form.reimbursement_reviewers.length;

    const reviewConfiguration = {
      company: {id: existingData.company.id},
      program: {id: existingData.id},
      use_employee_manager_as_primary_approver: employeeManagerAsPrimaryApprover,
      use_employee_manager_as_secondary_approver: employeeManagerAsSecondaryApprover,
      use_employee_manager_as_reimbursement_reviewer: employeeManagerAsReimbursementReviewer,
    };
    let reviewConfigurationMutation;
    let reviewConfigurationData;
    if (!existingData.program_reviewing_configuration) {
      reviewConfigurationMutation = createReviewConfiguration;
      reviewConfigurationData = {body: reviewConfiguration};
    } else {
      reviewConfigurationMutation = updateReviewConfiguration;
      reviewConfigurationData = {
        body: reviewConfiguration,
        configurationId: existingData.program_reviewing_configuration.id,
      };
    }

    reviewConfigurationMutation(reviewConfigurationData).then((results) => {
      if ('error' in results) {
        triggerCustomToast(
          'error',
          'There was a problem updating your program',
          'Please try again.',
        );
        return;
      }

      primaryApproverUpdate(primaryApprovers, results.data.id);
      secondaryApproverUpdate(secondaryApprovers, results.data.id);
      reimbursementReviewerUpdate(reimbursementReviewers, results.data.id);
      buttonCallback(results.data);
    });
  }

  function onSaveDraftClick() {
    if (Object.keys(dirtyFields).length === 0 && formMethods.formState.isValid) {
      navigate('/manage-programs');
      if (existingData.is_draft) {
        triggerCustomToast(
          'success',
          'Program saved as a draft',
          `Select ${existingData.name} from the list below to complete setup`,
        );
      }
      return;
    }

    formMethods.handleSubmit((form) => {
      onApproverReviewerUpdate(form, (updatedData: ProgramReviewingConfiguration) => {
        navigate('/manage-programs');

        if (existingData.is_draft) {
          triggerCustomToast(
            'success',
            'Program saved as a draft',
            `Select ${existingData.name} from the list below to complete setup`,
          );
        }
      });
    })();
  }
  return (
    <div className="flex flex-col h-full pb-900 justify-between">
      <div className="flex px-600 gap-x-6">
        <ComptProgressStepper steps={progressSteps} />
        <div className="flex flex-col">
          <h3 className="text-color-body1 mb-2">Assign approvers and reviewer</h3>
          <p className="body1 text-color-body2 mb-6">
            Determine who will be the approvers for each stage of a request.
          </p>
          <ApproverReviewerFields
            formMethods={formMethods}
            companyId={existingData.company.id}
            programId={existingData.id}
          />
        </div>
      </div>
      <ProgramFormFooter className="flex flex-shrink-0">
        <ComptButton
          size={ComptButtonSize.LARGE}
          buttonType={ComptButtonType.BORDERLESS}
          onClick={() => {
            formMethods.reset();
            navigate('/manage-programs');
          }}
        >
          Cancel
        </ComptButton>
        <div className="flex gap-x-6">
          <ComptButton
            onClick={onPreviousClick}
            size={ComptButtonSize.LARGE}
            buttonType={ComptButtonType.BORDERLESS}
          >
            Previous
          </ComptButton>
          <ComptButton
            onClick={onSaveDraftClick}
            size={ComptButtonSize.LARGE}
            buttonType={ComptButtonType.SECONDARY}
            disabled={isUpdating}
          >
            Save draft
          </ComptButton>
          <ComptButton
            onClick={onNextClick}
            size={ComptButtonSize.LARGE}
            buttonType={ComptButtonType.PRIMARY}
            disabled={isUpdating}
          >
            Next
          </ComptButton>
        </div>
      </ProgramFormFooter>
    </div>
  );
};
